.summaryText.MuiTypography-root {
  color: #264653;
  font-size: 14px;
}

.rangeText.MuiTypography-root {
  color: #264653;
  font-size: 14px;
  text-transform: capitalize;
}
.summaryVariable {
  color: #717171;
  min-width: 200px;
}

.summaryVariable.MuiTypography-root {
  font-size: 14px;
}

.summaryHeader.MuiTypography-root {
  color: #264653;
  font-size: 16px;
  font-weight: bold;
}

.summaryRole.MuiTypography-root {
  color: #C59D5F;
  font-size: 16px;
  font-weight: bold;
}
