.twoColumns {
    display: flex;
    margin: 10px;
}

.secondColumn {
    min-width: 400px;
    max-width: 400px;
    display:flex;
    align-items: center;
}

.variable {
    min-width: 300px;
    color: #264653;
    font-size: 14px;
}

.variable-subtitle.MuiTypography-root {
    min-width: 300px;
    color: #264653;
    font-size: 12px;
    font-style: italic;
}

.projectLink {
    color: #C59D5F;
    font-size: 12px;
}


/* Accordion */
.accordionTitle.MuiTypography-root {
    font-weight: bold;
    display: flex;
    align-items: center;
}

.accordionSummary.MuiAccordionSummary-root {
    background-color: #E6E6E6;
    color: #6A6A6A;
    border: solid 1px #C8CCCF;
}

/* Card */
.cardPadding.MuiCardContent-root {
    padding: 24px 16px 16px 60px;
}
