.customIcon {
    pointer-events: none;
    color: #264653;
}

.customIcon-transparent {
    pointer-events: none;
    color: transparent;
}

.customList {
    margin-top: 0px;
}

.customList li::marker {
    font-size: 14px;
    color: #264653;
}