.cardHeader.MuiTypography-root {
    text-transform: uppercase;
    text-align:center; 
    display: flex ;
    padding-top: 10px;
    justify-content: center;
    align-items: flex-end;
    font-size: 14px;
    font-weight: bold;
}

.cardContent.MuiTypography-root {
    font-size: 14px;
    text-align: center;
    margin: 16px 8px;
    padding-bottom: 60px;
}

.imageBox.MuiBox-root {
    display: flex;
    justify-content: center;
    height: 200px;
    padding: 16px 8px;
}