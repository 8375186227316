.centerPadding {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0px 50px;
}

.center {
    display: flex;
    justify-content: center;
    align-items: center;
}

.spaceBetween {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.flexStart {
    display: flex;
    justify-content: flex-start;
}

.flexEnd {
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.flexColumn {
    display: flex;
    flex-direction: column;
}

.absoluteCenter {
    position: absolute;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
}

/* Text */
.textCenter.MuiTypography-root {
    display: flex;
    justify-content: center;
    text-align: center;
    font-size: 14px;
}

.formCardHeader.MuiTypography-root {
    letter-spacing: 2px;
    font-weight: bolder;
    margin-bottom: 24px;
    margin-top: 8px;
}

/* .formCardHeader.MuiTypography-root {
    letter-spacing: 2px;
    font-weight: bolder;
    margin-bottom: 24px;
    margin-top: 8px;
} */
