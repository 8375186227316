#MessageOptions {
    display: flex;
    flex-direction: column;
}

#MessageArea {
    display:flex;
    align-items: flex-end;
    min-height: 350px;
    justify-content: flex-end;
}

#MessageSelected {
    height: 120px;
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
}