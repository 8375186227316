#NavBar {
    background: #264653;
    position: fixed;
    width: 250px;
    height: 100vh;
    top: 0;
    left: 0;
}

div > .navBarButton {
    text-transform: none;
    display: flex;
    color: #FFFFFF;
    width: 100%;
    justify-content: flex-start;
}

div > .navBarButton:hover {
    color: #C59D5F;
}

.logoutPosition {
    display: flex;
    justify-content: center;
    position: absolute;
    bottom: 30px;
}

div > .logoutButton {
    text-transform: none;
    display: flex;
    color: #264653;
    background-color: #FFFFFF;
    width: 100%;
    justify-content: flex-start;
    margin: 8px 56px;
    padding: 8px 24px;
}

div > .logoutButton:hover {
    color: #FFFFFF;
    background-color: #C59D5F ;
}

#hamburger {
    padding: 0;
    display: flex;
    justify-content: "flex-start";
    position: absolute;
    top: 15px;
    left: 0px;

}